import Repository from './Repository'
import { AxiosResponse } from 'axios'
import { ImageRequest } from "@/models/images/image.request"
import { ImageCreate } from "@/models/images/image.create"
import { ImageItem } from "@/models/images/image.item"

const resource = '/v1/images'

class ImageRepository {

    create(imageCreate: ImageCreate): Promise<AxiosResponse<ImageItem>> {
        return Repository.post(resource, imageCreate)
    }

    update(id: string, imageRequest: ImageRequest): Promise<AxiosResponse<ImageItem>> {
        return Repository.put(`${resource}/${id}`, imageRequest)
    }

    delete(id: string): Promise<AxiosResponse> {
        return Repository.delete(`${resource}/${id}`)
    }
}

export default new ImageRepository()