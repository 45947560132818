import { defineStore } from 'pinia'
import { initState, TOKEN } from './state'
import { Login } from '@/models/auth/login'
import AuthRepository from "@/services/AuthRepository"
import { Token } from '@/models/auth/token'

export const useAuthStore = defineStore({
  id: 'Auths',
  state: () => ({
    ...initState
  }),
  getters: {
    isLoggedIn: (state) => !!state.token
  },
  actions: {
    setToken: function (token: string) {
      this.token = token
      localStorage.setItem(TOKEN, token)
    },
    clearToken: function () {
      this.setToken('')
    },
    login: async function (login: Login) {
      const response = await AuthRepository.auth(login)
      this.setToken(response.data.token)
    },
    loginGoogle: async function (googleLogin: Token) {
      const response = await AuthRepository.authGoogle(googleLogin)
      this.setToken(response.data.token)
    },
    logout: function () {
      this.clearToken()
    }
  }
})
