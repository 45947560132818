export interface PlantForm {
    name: string,
    alias: string,
    scienceName: string,
    class: string,
    description: string,
    distribution: string,
    collection: string,
    feature: string,
    recipe: string,
    notes: string,
    publish: boolean
}

export const emptyPlantForm: PlantForm = {
    name: '',
    alias: '',
    scienceName: '',
    class: '',
    description: '',
    distribution: '',
    collection: '',
    feature: '',
    recipe: '',
    notes: '',
    publish: false
}