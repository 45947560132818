export const TOKEN = 'Token'

function getStringFromLocal (key: string) : string {
  return localStorage.getItem(key) || ''
}

export interface AuthsState {
  token: string
}

export const initState: AuthsState = {
  token: getStringFromLocal(TOKEN)
}
