import App from "@/App.vue"
import LoginPage from "@/pages/LoginPage.vue"
import LogoutPage from "@/pages/LogoutPage.vue"
import PlantListPage from "@/pages/PlantListPage.vue"

import { createRouter, createWebHistory } from 'vue-router'
import { useAuthStore } from "@/store/auth"
import PlantPage from "@/pages/PlantPage.vue"
import NewPlantPage from "@/pages/NewPlantPage.vue"

const routes = [
    {
        path: '',
        name: 'app',
        component: App,
        children: [
            {
                path: '',
                alias: 'index.html',
                name: 'parent',
                redirect: {name: 'plants'}
            },
            {
                path: '/plants',
                name: 'plants',
                component: PlantListPage
            },
            {
                path: 'plants/new',
                name: 'new-plant',
                component: NewPlantPage
            },
            {
                path: 'plants/:id',
                name: 'plant',
                component: PlantPage,
                props: true
            }
        ]
    },
    {
        path: '/login',
        name: 'login',
        component: LoginPage
    },
    {
        path: '/logout',
        name: 'logout',
        component: LogoutPage
    }
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})


router.beforeEach((to, from, next) => {
    const authStore = useAuthStore()

    if (to.name !== 'login' && !authStore.token) {
        next({name: 'login'})
    }

    window.scrollTo(0, 0)
    next()
})

export default router
