import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, mergeProps as _mergeProps, createBlock as _createBlock, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "p-2"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InputText = _resolveComponent("InputText")!
  const _component_Textarea = _resolveComponent("Textarea")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (!$props.edit)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, _toDisplayString($props.modelValue), 1 /* TEXT */))
      : _createCommentVNode("v-if", true),
    ($props.edit)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
          (!$props.multiLine)
            ? (_openBlock(), _createBlock(_component_InputText, _mergeProps({ key: 0 }, _ctx.$attrs, {
                value: $props.modelValue,
                onInput: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('update:modelValue', $event.target.value)))
              }), null, 16 /* FULL_PROPS */, ["value"]))
            : _createCommentVNode("v-if", true),
          ($props.multiLine)
            ? (_openBlock(), _createBlock(_component_Textarea, _mergeProps({ key: 1 }, _ctx.$attrs, {
                value: $props.modelValue,
                onInput: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('update:modelValue', $event.target.value)))
              }), null, 16 /* FULL_PROPS */, ["value"]))
            : _createCommentVNode("v-if", true)
        ], 64 /* STABLE_FRAGMENT */))
      : _createCommentVNode("v-if", true)
  ], 64 /* STABLE_FRAGMENT */))
}