import Repository from './Repository'
import { AxiosResponse } from 'axios'
import { PlantItem } from "@/models/plant/plant.item"
import { PlantDetail } from "@/models/plant/plant.detail"
import { PlantForm } from "@/models/plant/plant.form"

const resource = '/v1/plants'

class PlantRepository {

    getPlant(id: string): Promise<AxiosResponse<PlantDetail>> {
        return Repository.get(`${resource}/${id}`)
    }

    getPlants(): Promise<AxiosResponse<PlantItem[]>> {
        return Repository.get(`${resource}`)
    }

    delete(id: string): Promise<AxiosResponse> {
        return Repository.delete(`${resource}/${id}`)
    }

    update(id: string, plantForm: PlantForm): Promise<any> {
        return Repository.put(`${resource}/${id}`, plantForm)
    }

    create(plantForm: PlantForm): Promise<AxiosResponse<PlantDetail>> {
        return Repository.post(resource, plantForm)
    }
}

export default new PlantRepository()