import 'primevue/resources/themes/saga-blue/theme.css'
import 'primevue/resources/primevue.min.css'
import 'primeicons/primeicons.css'
import "primeflex/primeflex.css"

import AppMain from "@/AppMain.vue"

import { createApp, reactive } from 'vue'
import router from './router'
import PrimeVue from 'primevue/config'
import BadgeDirective from 'primevue/badgedirective'
import Button from 'primevue/button'
import Card from 'primevue/card'
import Checkbox from 'primevue/checkbox'
import Column from 'primevue/column'
import ConfirmDialog from 'primevue/confirmdialog'
import ConfirmPopup from 'primevue/confirmpopup'
import ConfirmationService from 'primevue/confirmationservice'
import DataTable from 'primevue/datatable'
import DataView from 'primevue/dataview'
import Dialog from 'primevue/dialog'
import FileUpload from 'primevue/fileupload'
import InputText from 'primevue/inputtext'
import Password from 'primevue/password'
import Ripple from 'primevue/ripple'
import Textarea from 'primevue/textarea'
import Toast from 'primevue/toast'
import ToastService from 'primevue/toastservice'
import Tooltip from 'primevue/tooltip'
import { primeConfig } from './configs/prime.config'
import Disable from "@/directives/disable"
import { createPinia } from "pinia"
import { initializeApp } from 'firebase/app'
import StyleClass from "primevue/styleclass"


const firebaseConfig = {
    apiKey: "AIzaSyCD9dYm9ghV1CeSKZNA_B6oBufnBiVMSKI",
    authDomain: "cay-thuoc-nam.firebaseapp.com",
    projectId: "cay-thuoc-nam",
    storageBucket: "cay-thuoc-nam.appspot.com",
    messagingSenderId: "820102651525",
    appId: "1:820102651525:web:6e75374bf0b15715a113ec",
    measurementId: "G-ZEZ983W6BX"
};

initializeApp(firebaseConfig)

const app = createApp(AppMain)

app.directive('styleclass', StyleClass)
app.config.globalProperties.$appState = reactive({inputStyle: 'outlined'})
app.use(PrimeVue, {
    locale: primeConfig(),
    ripple: true
})
app.use(ConfirmationService)
app.use(ToastService)
app.use(router)
app.use(createPinia())

app.directive('tooltip', Tooltip)
app.directive('ripple', Ripple)
app.directive('badge', BadgeDirective)
app.directive('disable', Disable)

app.component('Button', Button)
app.component('Card', Card)
app.component('Checkbox', Checkbox)
app.component('Column', Column)
app.component('ConfirmDialog', ConfirmDialog)
app.component('ConfirmPopup', ConfirmPopup)
app.component('DataTable', DataTable)
app.component('DataView', DataView)
app.component('Dialog', Dialog)
app.component('FileUpload', FileUpload)
app.component('InputText', InputText)
app.component('Password', Password)
app.component('Textarea', Textarea)
app.component('Toast', Toast)

app.mount('#app')
