import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap" }
const _hoisted_2 = { class: "text-500 w-6 md:w-2 font-medium" }
const _hoisted_3 = { class: "text-900 w-full md:w-10 md:flex-order-0 flex-order-1" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("li", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, _toDisplayString($props.label), 1 /* TEXT */),
    _createElementVNode("div", _hoisted_3, [
      _createVNode($setup["ToggleableField"], {
        class: "w-full",
        "model-value": $props.modelValue,
        rows: $props.rows,
        "multi-line": $props.multiLine,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('update:modelValue', $event))),
        edit: $props.edit
      }, null, 8 /* PROPS */, ["model-value", "rows", "multi-line", "edit"])
    ])
  ]))
}