import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-720e9fba"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "flex justify-content-between" }
const _hoisted_2 = { class: "card" }
const _hoisted_3 = { class: "col-12 md:col-4" }
const _hoisted_4 = ["src", "alt", "onClick"]
const _hoisted_5 = {
  key: 1,
  class: "image max-w-full"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _component_Dialog = _resolveComponent("Dialog")!
  const _component_FileUpload = _resolveComponent("FileUpload")!
  const _component_Card = _resolveComponent("Card")!
  const _component_DataView = _resolveComponent("DataView")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_Dialog, {
      header: "Hình ảnh",
      visible: $setup.displayPopup,
      "onUpdate:visible": _cache[0] || (_cache[0] = ($event: any) => (($setup.displayPopup) = $event)),
      modal: true
    }, {
      footer: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _createVNode(_component_Button, {
            label: "Xoá ảnh",
            class: "p-button-danger",
            loading: $setup.loading,
            onClick: $setup.deleteImage
          }, null, 8 /* PROPS */, ["loading"]),
          _createVNode(_component_Button, {
            label: "Lưu thông tin",
            loading: $setup.loading,
            onClick: $setup.saveImage
          }, null, 8 /* PROPS */, ["loading"])
        ])
      ]),
      default: _withCtx(() => [
        ($setup.image.id)
          ? (_openBlock(), _createBlock($setup["ImageViewPopup"], {
              key: 0,
              image: $setup.image
            }, null, 8 /* PROPS */, ["image"]))
          : _createCommentVNode("v-if", true)
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["visible"]),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_DataView, {
        value: $setup.displayImages,
        layout: "grid"
      }, {
        grid: _withCtx((slotProps) => [
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_Card, {
              style: {"text-align":"center"},
              class: "m-2"
            }, {
              content: _withCtx(() => [
                _createElementVNode("div", null, [
                  (slotProps.data.id)
                    ? (_openBlock(), _createElementBlock("img", {
                        key: 0,
                        src: $setup.getImageUrl(slotProps.data.id),
                        alt: slotProps.data.caption,
                        class: "image max-w-full",
                        onClick: ($event: any) => ($setup.showImage(slotProps.data))
                      }, null, 8 /* PROPS */, _hoisted_4))
                    : (_openBlock(), _createElementBlock("div", _hoisted_5, [
                        _createVNode(_component_FileUpload, {
                          onBeforeSend: $setup.sendHeader,
                          mode: "basic",
                          onSelect: $setup.selectImage,
                          url: `/api/v1/plants/${$props.plant.id}/images`
                        }, null, 8 /* PROPS */, ["url"])
                      ])),
                  _createElementVNode("div", null, _toDisplayString(slotProps.data.caption ?? '-'), 1 /* TEXT */)
                ])
              ]),
              _: 2 /* DYNAMIC */
            }, 1024 /* DYNAMIC_SLOTS */)
          ])
        ]),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["value"])
    ])
  ], 64 /* STABLE_FRAGMENT */))
}