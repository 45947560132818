import { ImageItem } from "@/models/images/image.item"
import { emptyPlantForm, PlantForm } from "@/models/plant/plant.form"

export interface PlantDetail extends PlantForm {
    id: string,
    images: ImageItem[]
}

export const emptyPlantDetail: PlantDetail = {
    ...emptyPlantForm,
    id: '',
    images: []
}