import { defineStore } from "pinia"
import { PlantItem } from "@/models/plant/plant.item"
import PlantRepository from "@/services/PlantRepository"
import { emptyPlantDetail, PlantDetail } from "@/models/plant/plant.detail"
import { PlantForm } from "@/models/plant/plant.form"

const repository = PlantRepository


export const usePlantStore = defineStore({
    id: 'Plants',
    state: () => ({
        plant: emptyPlantDetail,
        plants: [] as PlantItem[],
    }),
    actions: {
        get: async function (id: string) {
            const response = await repository.getPlant(id)
            this.plant = response.data
        },
        getAll: async function () {
            const response = await repository.getPlants()
            this.plants = response.data
        },
        create: async function (plantForm: PlantForm): Promise<PlantDetail> {
            const response = await repository.create(plantForm)
            this.plants = [...this.plants, response.data]
            return response.data
        },
        update: async function (id: string, plantForm: PlantForm) {
            await repository.update(id, plantForm)
            this.plants = this.plants.map(item => item.id === id ? {
                ...item,
                ...plantForm
            } : {
                ...item
            })
            if (this.plant.id === id) {
                this.plant = {
                    ...this.plant,
                    ...plantForm
                }
            }
        },
        delete: async function (id: string) {
            await repository.delete(id)
            this.plants = this.plants.filter(x => x.id !== id)
        }
    }
})